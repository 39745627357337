<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col cols="12" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="recordData.logo_url" :text="avatarText(recordData.name)" :variant="`light-primary`" size="104px"
            rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0 break-word">
                {{ recordData.name }}
              </h4>
              <span class="card-text">{{ recordData.admin ? recordData.admin.email : '' }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$can('update', 'Schools')">
                <b-button :to="{ name: 'school-edit', params: { id: recordData.id } }" variant="primary"
                  v-if="!recordData.deleted_at" class="mr-1 mb-1">
                  {{ $t('forms.edit') }}
                </b-button>

                <b-button variant="primary" class="mr-1 mb-1"
                  :to="{ name: 'school-upload', params: { id: recordData.id } }">
                  <feather-icon icon="UploadIcon" size="16" />
                  {{ $t('Handle') }}
                </b-button>
              </template>

              <template v-if="$can('delete', 'Schools')">
                <b-button variant="outline-danger" class="mr-1 mb-1" v-if="!recordData.deleted_at" @click="deleteModel">
                  {{ $t('forms.delete') }}
                </b-button>
              </template>

              <template v-if="$can('update', 'Schools')">
                <b-button variant="outline-danger" class="mr-1 mb-1" v-if="recordData.deleted_at" @click="recoverLicenceModel">
                  {{ $t('forms.recover') }}
                </b-button>
              </template>
              <b-button variant="outline-secondary" type="button" class="mb-1" @click="hasHistory()
                  ? $router.go(-1)
                  : $router.push({ name: 'schools' })">
                {{ $t('forms.go_back') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12">
        <table class="mt-2 w-100">
          <tr>
            <th class="pb-50" style="width:200px;">
              <feather-icon icon="HomeIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.address')}} - {{$t('fields.zip_code')}}</span>
            </th>
            <td class="pb-50">
              {{ recordData.address }} {{ recordData.address && recordData.cp ? '-' : '' }} {{ recordData.cp }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" style="width:200px;">
              <feather-icon icon="GlobeIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.country')}} - {{$t('fields.community')}}</span>
            </th>
            <td class="pb-50">
              {{ recordData.country }} - {{ recordData.community }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" style="width:200px;">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.phone')}}</span>
            </th>
            <td class="pb-50">
              {{ recordData.phone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50" style="width:200px;">
              <feather-icon icon="MessageCircleIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.bilingual_school')}}</span>
            </th>
            <td class="pb-50">
              {{ recordData.bilingual == 1 ? $t('Yes') : $t('No') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="BriefcaseIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('models.partner')}}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ recordData.payment_method_id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.payment_status')}} </span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ recordData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="ServerIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.educational_stages')}}</span>
            </th>
            <td class="pb-50">
              <span v-for="(x, i) in educationalStages" :key="i">{{ x }}{{ i + 1 == educationalStages.length ? '' : ', '
              }}</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="SmileIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.nee')}}</span>
            </th>
            <td class="pb-50">
              <span v-for="(x, i) in specialNeeds" :key="i">
                {{ $t('special_needs.' + x) }}{{ i + 1 == specialNeeds.length ? '' : ', ' }}</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.type')}}</span>
            </th>
            <td>
              {{ recordData.type == 0 ? 'Público' : recordData.type == 1 ? 'Concertado' : 'Privado' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.created_at')}}</span>
            </th>
            <td>
              {{ new Date(recordData.created_at).toLocaleDateString() }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="EditIcon" class="mr-75" />
              <span class="font-weight-bold">{{$t('fields.updated_at')}}</span>
            </th>
            <td>
              {{ new Date(recordData.updated_at).toLocaleDateString() }}
            </td>
          </tr>
          <tr v-if="recordData.deleted_at">
            <th>
              <feather-icon icon="XCircleIcon" class="mr-75" />
              <span class="font-weight-bold">Deleted at</span>
            </th>
            <td>
              {{ new Date(recordData.deleted_at).toLocaleDateString() }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { recoverSchool, deleteSchool } from '@/api/routes'
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    recordData: {
      type: Object,
      required: true,
    },
    special_needs: {
      type: Array,
      required: true
    }
  },
  mounted() {
    // console.log("Dreamer Info card", this.recordData)
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    hasHistory() {
      return window.history.length > 2
    },
    recoverLicenceModel() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to recover this school?', {
          title: 'Recover School',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            let data = {
              'school_id': this.recordData.id
            }
            this.$http.post(recoverSchool, data).then(response => {
              if (response.data.status == 200) {
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    deleteModel() {
      this.$bvModal
        .msgBoxConfirm(this.$t('schools.sure'), {
          title: this.$t('schools.delete'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('schools.yes'),
          cancelTitle: this.$t('schools.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            let data = {
              'school_id': this.recordData.id
            }
            this.$http.post(deleteSchool, data).then(response => {
              if (response.data.status == 200) {
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  computed: {
    specialNeeds() {
      let arr = JSON.parse(this.recordData.special_needs_type)
      let sn_arr = []
      if (arr) {
        arr.map(x => {
          this.special_needs.map(s => {
            if (s.id == x) {
              sn_arr.push(s.name)
            }
          })

        })
      }
      return sn_arr
    },
    educationalStages() {
      let arr = JSON.parse(this.recordData.educational_stages)
      let educ_st = []
      if (arr) {
        arr.map(x => {
          switch (x) {
            case '1':
              educ_st.push('Infantil')
              break;
            case '2':
              educ_st.push('Primaria')
              break;
            case '3':
              educ_st.push('Secundaria')
              break;
            case '4':
              educ_st.push('Otros Centros')
              break;
          }
        })
      }
      return educ_st
    }
  }
}
</script>

<style></style>
